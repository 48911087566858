<template>
    <div class="login">
        <!-- 头部 -->
        <div class="options">
            <div class="mains">
                <img class="lgimg" :src="require('@/assets/LMlogo.png')" />
                <div class="box">
                    <div class="dropdown">
                        <span>当前：</span>
                        <el-dropdown trigger="click" split-button type="primary" @command="handleCommand">
                            {{ designate }}
                            <el-dropdown-menu slot="dropdown">
                                <!-- 
                                <el-dropdown-item v-for="(item,i) in 10" :key="i"
                                 -->
                                <el-dropdown-item v-for="(item,i) in schemeArray" :key="i"
                                    :command="{url: item.url,font: `${item.planNo}. ${schemeType[item.status]}`,planId:item.planId,status:item.status,pdfUrl:item.pdfUrl}">
                                    {{`${item.planNo}. ${schemeType[item.status]}`}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <ul class="clearfix">
                        <li v-for="(item, i) in options" :key="i">
                            <div class="image">
                                <img @click="choice.call(this, i, item.visible)" :src="item.url" />
                            </div>
                            <p>{{ item.font }}</p>
                        </li>
                    </ul>
                </div>
                <div @click="approvalPlanWeb" :class="{none:status != 2,right:true}">
                    <el-image :src="require('@/assets/LM/make.png')"></el-image>
                    <div>确认制作</div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="left">
                <!-- 中间的选项按钮 -->
                <div class="content">
                    <p id="loading"
                        style="position: absolute; top:50%; left:50%; margin: -50px 0 0 -50px;text-align:center"></p>
                    <canvas class="scene"></canvas>
                    <ul class="clearfix">
                        <li @click="retreat">
                            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/后退.png`)" />
                        </li>
                        <li @click="last">
                            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/上一步.png`)" />
                        </li>
                        <li @click="autoAdvance">
                            <img
                                :src="[isPlay ? require(`@/assets/#病例详情#3D方案切图9.9/PC/暂停.png`) : require(`@/assets/#病例详情#3D方案切图9.9/PC/开始.png`)]" />
                        </li>
                        <li @click="next">
                            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/下一步.png`)" />
                        </li>
                        <li @click="advance">
                            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/前进.png`)" />
                        </li>
                    </ul>
                </div>
                <!-- 下面的进度条 -->
                <div class="foot">
                    <div class="mains">
                        <ul class="curTop" v-show="panTop" ref="curTop">
                            <div v-show="planIndext == 0" class="planOne plan"
                                :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`">
                                <span v-show="planIndext == 0" class="num">0</span>
                            </div>
                            <span class="bottomOne" v-show="!panBottom && panTop">0</span>
                            <li v-for="(item, i) in planArrayT" :key="i"
                                :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur') : ''"
                                @click="planClick.call(this, i)" :style="{width: topwidth + 'px'}">

                                <div class="plan" v-show="planIndext == i + 1"
                                    :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`">
                                    <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
                                </div>
                                <span class="bottomIndex" v-if="(i + 1) % 5 === 0"
                                    v-show="!panBottom && panTop">{{ i + 1 }}</span>
                            </li>
                            <li class="foot_sz">{{`${planIndext}/${planArrayT.length}` }}</li>
                        </ul>
                        <!-- 下进度条 -->
                        <ul class="curBottom" v-show="panBottom" ref="curBottom">
                            <div v-show="planIndext == 0  && !panTop && panBottom" class="planOne plan"
                                :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`">
                                <span v-show="planIndext == 0" class="num">0</span>
                            </div>
                            <span class="bottomOne">0</span>
                            <li v-for="(item, i) in planArrayB" :key="i"
                                :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur'): ''"
                                @click="planClick.call(this, i)" :style="{width: bottomwidth + 'px'}">
                                <div class="plan" v-show="planIndext == i + 1 && !panTop && panBottom"
                                    :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`">
                                    <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
                                </div>
                                <span class="bottomIndex" v-if="(i + 1) % 5 === 0">{{ i + 1 }}</span>
                            </li>
                            <li class="foot_sz">{{`${planIndext}/${planArrayB.length}` }}</li>
                        </ul>
                    </div>
                    <!-- 上进度条 -->

                    <!-- <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/未标题-1.png`)" /> -->
                </div>
            </div>
            <div class="right">
                <ul class="list" v-show="isback">
                    <li @click="tabClick({index:'0'})">
                        <el-image :src="require('@/assets/LM/bl-normal.png')"></el-image>
                        <span>患者照片</span>
                    </li>
                    <li @click="tabClick({index:'1'})">
                        <el-image :src="require('@/assets/LM/wd-normal.png')"></el-image>
                        <span>我的意见</span>
                    </li>
                    <li @click="tabClick({index:'2'})">
                        <el-image :src="require('@/assets/LM/yj-normal.png')"></el-image>
                        <span>方案矫治说明</span>
                    </li>
                </ul>
                <el-tabs type="border-card" @tab-click="tabClick" v-model="tabIndex" v-show="!isback">
                    <el-tab-pane name="0">
                        <span slot="label">
                            <el-image class="backImage" @click.stop="tabClick({index:'-1'})"
                                :src="require('@/assets/LM/back-down.png')"></el-image>
                            <el-image ref="img0" :src="require('@/assets/LM/bl-normal-Small.png')"></el-image> 患者病例
                        </span>
                        <information v-if="tabIndex == 0" :caseId="caseId"></information>
                    </el-tab-pane>
                    <el-tab-pane name="1">
                        <span slot="label">
                            <el-image ref="img1" :src="require('@/assets/LM/wd-normal-Small.png')"></el-image> 我的意见
                        </span>
                        <idea v-if="tabIndex == 1" :planId="planId" :caseId="caseId"></idea>
                    </el-tab-pane>
                    <el-tab-pane name="2">
                        <span slot="label">
                            <el-image ref="img2" :src="require('@/assets/LM/yj-normal-Small.png')"></el-image> 方案矫治说明
                        </span>
                        <pdf v-if="tabIndex == 2" :pdfUrl="pdfUrl"></pdf>
                    </el-tab-pane>

                </el-tabs>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        getQueryString
    } from '@/util/validate';
    import {
        getHashCode
    } from "@/util/index";
    import {
        render
    } from "../../render/render";
    import {
        initStl
    } from "../../render/render";
    import {
        pointModel
    } from "../../render/render";
    import {
        playModel
    } from "../../render/render";

    import idea from './components/idea.vue'
    import pdf from './components/pdf.vue'
    import information from './components/information.vue'
    let TIMERSTEP = null;
    export default {
        components: {
            idea,
            pdf,
            information
        },
        data() {
            return {
                caseId: '',
                designate: `方案1`,
                options: [{
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/双颌.png`), //显示的图片
                        font: "双颌", //显示的文字
                        visible: 0, //0显示双颌，1显示上颌，2显示下颌
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/单颌.png`),
                        font: "单颌",
                        visible: 1,
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/左侧.png`),
                        font: "左侧",
                        visible: 0,
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/正面.png`),
                        font: "正面",
                        visible: 0,
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/右侧.png`),
                        font: "右侧",
                        visible: 0,
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/下颌.png`),
                        font: "下颌",
                        visible: 2,
                    },
                    {
                        url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/上颌.png`),
                        font: "上颌",
                        visible: 1,
                    },
                ],
                // 方案状态
                status: 0,
                //上进度进度
                planArrayT: [],
                //下进度进度
                planArrayB: [],
                //上进度索引
                planIndext: 0,
                // 上进度是否显示
                panTop: true,
                // 下进度是否显示
                panBottom: true,
                // 是否自动前进
                autoFlag: {
                    flag: false,
                    interval: null,
                },
                // 方案数组集合
                schemeArray: [],
                schemeType: ['方案修改中', '方案需修改', '方案待确认', '方案已确认'],
                istop: true,
                topwidth: 0,
                bottomwidth: 0,
                curstep: 0,
                totalstep: [],
                isPlay: false,
                caseUid: "",
                caseId: '',
                // 切换显示
                isback: true,

                // pdf 链接
                pdfUrl: '',

                tabIndex: '1',

                width:0,
            };
        },
        created() {
            // this.$store.commit('changeSCopyRight', false)

            this.caseId = getQueryString('caseId');
            this.planId = getQueryString('planId');
        },

           mounted() { this.$store.commit('changeCopy',true)
            this.plans();
            localStorage.setItem('winWidth','150');
        },
        methods: {
            //初始化
            init() {
                // this.plans();
                //   异步请求数据,用延时器来代替
                setTimeout(() => {
                    //控制进度条灰色 1 代表灰色 0代表蓝色
                    if (this.totalstep != undefined && this.totalstep.length > 0) {
                        this.planArrayT = new Array(this.totalstep.length - 1);
                        this.planArrayB = new Array(this.totalstep.length - 1);
                        for (var j = 0; j < this.totalstep.length - 1; j++) {
                            this.planArrayT[j] = {
                                type: this.totalstep[j][0]
                            };
                            this.planArrayB[j] = {
                                type: this.totalstep[j][1]
                            };
                        }
                    } else {
                        this.planArrayT = [];
                        this.planArrayB = [];
                    }
                    this.planIndext = 0;
                    this.topwidth =
                        this.$refs.curTop.clientWidth / (this.planArrayT.length + 1);
                    this.bottomwidth =
                        this.$refs.curBottom.clientWidth / (this.planArrayB.length + 1);
                }, 200);
            },
            //方案列表
            plans() {
                this.$http({
                    url: '/caseInfo/plans',
                    method: 'get',
                    params: {
                        caseId: Number(this.caseId),
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                         data = JSON.parse(data.data)
          console.log('4',data);

                        let index = 0;
                        for(let i in data) {
                            if(data[i].planId == this.planId) {
                                index = i;
                            }
                        }
                        this.designate = `${data[index].planNo}. ${this.schemeType[data[index].status]}`

                        this.pdfUrl = data[index].pdfUrl;

                        this.status = data[index].status;

                        this.schemeArray = data;
                        this.getData(data[index].url);
                    }

                })
            },
            // 双颌，单颌，左侧，右侧选项按钮点击函数,参数"i"是点中的那张图,从0开始;
            choice(i, visible) {
                pointModel(i);
                if (i === 0) {
                    //双颌显示
                    this.panTop = true;
                    this.panBottom = true;
                } else if (i === 1) {
                    //上颌显示
                    if (this.istop) {
                        this.panTop = true;
                        this.panBottom = false;
                        this.istop = false;
                    } else {
                        this.panTop = false;
                        this.panBottom = true;
                        this.istop = true;
                    }
                } else if (i === 5) {
                    //下颌显示
                    this.panTop = false;
                    this.panBottom = true;
                } else if (i === 6) {
                    //上颌显示
                    this.panTop = true;
                    this.panBottom = false;
                }
            },
            // 方案1，方案2，方案3，选项按钮点击函数,参数"command"是选中的选项。;
            handleCommand(command) {
                this.planId = command.planId;
                this.designate = command.font;
                this.status = command.status;
                this.pdfUrl = command.pdfUrl;
                var currenturl = window.location.href;
                var newUrl = (currenturl.split("?"))[0];
                // history.pushState('', '', `http://localhost:8080/#/threeLM?caseId=1008925&planId=${this.planId}`)
                history.pushState('', '', `${newUrl}?caseId=${this.caseId}&planId=${this.planId}`)
                this.totalstep = [];
                this.init();
                this.getData(command.url);
            },
            //上进度条点击
            planClick(i) {
                this.curstep = i + 1;
                this.gotocurstep();
            },
            // 后退
            retreat() {
                this.curstep = 0;
                this.gotocurstep();
            },
            // 上一步
            last() {
                this.curstep--;
                this.gotocurstep();
            },
            // 自动前进
            autoAdvance() {
                this.width = 502;
                this.isPlay = !this.isPlay;
                if (this.isPlay) {
                    if (this.curstep == this.totalstep.length - 1) {
                        this.curstep = -1;
                        TIMERSTEP = setInterval(() => {
                            if (this.curstep >= this.totalstep.length - 1) {
                                this.isPlay = false;
                                this.curstep = this.totalstep.length - 1;
                                this.planIndext = this.curstep;
                                clearInterval(TIMERSTEP);
                                TIMERSTEP = null;
                                return;
                            }
                            this.curstep++;
                            this.planIndext = this.curstep;
                            playModel(this.curstep);
                            render(this.width);
                        }, 500);
                    } else {
                        TIMERSTEP = setInterval(() => {
                            if (this.curstep >= this.totalstep.length - 1 || this.curstep < 0) {
                                this.isPlay = false;
                                this.curstep = this.totalstep.length - 1;
                                this.planIndext = this.curstep;
                                clearInterval(TIMERSTEP);
                                TIMERSTEP = null;
                                return;
                            }
                            this.curstep++;
                            this.planIndext = this.curstep;
                            playModel(this.curstep);
                            render(this.width);
                        }, 500);
                    }
                } else {
                    clearInterval(TIMERSTEP);
                    TIMERSTEP = null;
                }
            },
            // 下一步
            next() {
                this.curstep++;
                this.gotocurstep();
            },
            // 前进
            advance() {
                this.curstep = this.totalstep.length - 1;
                this.gotocurstep();
            },
            async getData(url) {
                let loadingdom = document.getElementById("loading");
                loadingdom.innerText = "方案加载中……";
                this.caseUid = getHashCode(url).toString(36);
                initStl(this.caseUid, url, false);
                TIMERSTEP = setInterval(() => {
                    if (localStorage.getItem(this.caseUid) == null) {

                    } else {
                        loadingdom.innerText = "";
                        this.totalstep = JSON.parse(localStorage.getItem(this.caseUid));
                        localStorage.removeItem(this.caseUid);
                        clearInterval(TIMERSTEP);
                        TIMERSTEP = null;
                        this.init();
                    }
                }, 100);
            },
            gotocurstep() {
                if (this.curstep < 0) {
                    this.curstep = 0;
                } else if (this.curstep >= this.totalstep.length) {
                    this.curstep = this.totalstep.length - 1;
                }
                this.planIndext = this.curstep;
                playModel(this.curstep);
            },
            tabClick(e) {
                let index = e.index;
                if(index == '-1') {
                    this.isback = true;
                    localStorage.setItem('winWidth','150');
                    return;
                }
                this.isback = false;
                localStorage.setItem('winWidth','504');
                this.tabIndex = index;
                // console.log(e.index);
                this.$refs.img0.$el.children[0].src = require('@/assets/LM/bl-normal-Small.png');
                this.$refs.img1.$el.children[0].src = require('@/assets/LM/wd-normal-Small.png');
                this.$refs.img2.$el.children[0].src = require('@/assets/LM/yj-normal-Small.png');
                this.width = 502;
                switch (index) {
                    case '0':
                        this.$refs.img0.$el.children[0].src = require('@/assets/LM/bl-down-Small.png');
                        break;
                    case '1':
                        this.$refs.img1.$el.children[0].src = require('@/assets/LM/wd-down-Small.png');
                        break;
                    case '2':
                        this.$refs.img2.$el.children[0].src = require('@/assets/LM/yj-down-Small.png');
                        break;
                }
            },
            // 批准方案
            approvalPlanWeb() {
                console.log(123)
                if (this.status != 2) return;
                this.$http({
                    url: '/caseInfo/approvalPlanWeb',
                    method: 'POST',
                    data: {
                        caseId: this.caseId,
                        planId: this.planId,
                    }
                }).then(({
                    data
                }) => {
                    if (data.code == 200) {
                        this.$message({
                            message: "确认成功",
                            type: 'success',
                            duration: 1500,
                        })
                        this.plans();
                    }
                })
            },
        },  
    };
</script>
<style scoped lang="less">
    .login {
        height: calc(100vh - 60px);
        overflow: hidden;
        position: relative;
        background: #eeeff2;

        /deep/.dlbgimg {
            height: 100%;
            display: block;
            margin-top: -80px;
        }

        .topdhlg {
            width: 100%;
            height: 80px;
            background: #353b43;
            position: relative;
            box-shadow: 0px 5px 20px #353b43;
        }
    }

    .options {
        height: 68px;
        width: 100%;
        margin-bottom: 16px;

        .mains {
            position: relative;
            height: 100%;
            display: flex;
            justify-content: space-between;

            /deep/ .el-row {
                width: 70%;
                margin: 0 auto;
                margin-left: auto !important;
                margin-right: auto !important;
            }

            .lgimg {
                display: block;
                width: 135px;
                z-index: 99;
                margin-right: 8px;
                height: 68px;
            }

            .box {
                background: #4d90cd;
                display: flex;
                height: 100%;
                align-items: center;
                width: 100%;

                /deep/.dropdown {
                    position: relative;
                    z-index: 99;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    margin-right: 10%;

                    &>span {
                        font-size: 14px;
                        color: #fff;
                        margin: 0 10px;
                    }

                    .el-dropdown {
                        width: 170px;
                        height: 28px;

                        .el-button-group {
                            height: 28px;
                            display: flex;

                            .el-button--primary {
                                width: 100%;
                                height: 100%;
                                color: #333;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 0;

                                &::before {
                                    display: none;
                                }

                                span {
                                    color: #fff;
                                }
                            }

                            .el-dropdown__caret-button {
                                width: 20%;
                            }
                        }

                    }

                }

                ul {
                    width: 504px;
                    height: 100%;
                    display: flex;

                    li {
                        flex: 1;
                        height: 100%;
                        position: relative;

                        &:hover {
                            background: #88bdef;
                            cursor: pointer;
                        }

                        .image {
                            width: 100%;
                            height: 75%;

                            position: relative;

                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                max-height: 80%;
                                border-radius: 10px;

                            }

                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        p {
                            height: 25%;
                            line-height: 25%;
                            margin-top: 0px;
                            width: 100%;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }
            }

            .right {
                height: 100%;
                width: 130px;
                min-width: 130px;
                margin-left: 20px;
                background: #1d69af;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                cursor: pointer;


                .el-image {
                    margin-right: 10px;
                }
            }

            .none {
                cursor: not-allowed;
                background-color: gray;
            }
        }
    }

    .container {
        display: flex;
        justify-content: flex-end;
        height: calc(100vh - 68px - 16px - 60px);

        .left {
            width: 100%;
            overflow: hidden;
            position: relative;
            background: #dfe0e2;

            .content {
                height: calc(100% - 110px);

                >img {
                    display: block;
                    margin: 0 auto;
                }

                position: relative;

                ul {
                    width: 380px;
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);

                    li {
                        float: left;
                        margin: 0 20px;
                    }
                }
            }

            .foot {
                position: absolute;
                bottom: 0;
                background: #eeeff2;
                width: 100%;
                height: 100px;
                padding-top: 28px;
                box-sizing: border-box;

                .mains {
                    padding: 0 20px;
                }

                .hide {
                    display: none;
                }

                ul {
                    width: 95%;
                    margin-bottom: 10px;
                    // height: 50px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    .plan {
                        position: absolute;
                        height: 30px;
                        width: auto;
                        top: -30px;
                        right: -12.44px;
                        // transition: all 0.1s linear;
                        width: 25px;
                        height: 30px;
                        background-size: cover;
                    }

                    .planOne {
                        left: -12.44px;
                    }

                    .num {
                        position: absolute;
                        // top: -30px;
                        width: 100%;
                        text-align: center;
                        color: #fff;
                        // transition: all 0.1s linear;

                    }

                    .bottomOne {
                        position: absolute;
                        bottom: -40px;
                        left: 0;
                    }

                    .bottomIndex {
                        position: absolute;
                        bottom: -30px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    li {
                        width: 50px;
                        height: 10px;
                        border: 1px solid #1175d2;
                        border-radius: 3px;
                        position: relative;
                        cursor: pointer;
                        box-sizing: border-box;
                    }

                    .foot_sz {
                        position: absolute;
                        right: -90px;
                        top: -5px;
                        width: 40px;
                        border: none;
                        font-size: 14px;
                        color: #1175d2;
                    }

                    .cur {
                        background: #1175d2;
                        // transition: all 0.1s linear;
                    }

                    .hscur {
                        background: #ccc;
                        border: 1px solid #ccc;
                    }
                }

                img {
                    width: 100%;
                }
            }

        }

        .right {

            background: #eeeff2;
            height: 100%;

            .list {
                width: 130px;
                min-width: 130px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    border: 1px solid #4d90cd;
                    width: 96px;
                    height: 88px;
                    margin: 16px 0;
                    border-radius: 10px;
                    color: #4d90cd;
                    font-size: 14px;
                    cursor: pointer;
                }
            }

            /deep/.el-tabs {
                height: 100%;
                width: 504px;
                box-sizing: border-box;

                .el-tabs__header {
                    position: relative;
                    height: 40px;
                    border: none;

                    .backImage {
                        position: absolute;
                        left: -23px;
                        top: 10px;
                    }

                    .el-tabs__nav {
                        height: 40px;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        .el-tabs__item {
                            height: 100%;
                            padding: 0 25px;
                            border: 0;
                            background: #d7d7d7;

                            span {
                                height: 40px;
                                line-height: 40px;
                                display: block;
                                font-size: 14px;
                                color: #666;

                                .el-image {
                                    vertical-align: middle;
                                }
                            }
                        }

                        .is-active {
                            background: #4e90cd;

                            span {
                                color: #fff;
                            }
                        }
                    }
                }

                .el-tabs__content {
                    height: calc(100% - 40px);
                    box-sizing: border-box;
                    padding: 0;

                    .el-tab-pane {
                        height: 100%;
                    }
                }
            }
        }
    }
</style>